// login

// generado con https://cssgradient.io/
.bg-gradient-indigo {
  // background: rgb(6, 3, 18);
  // background: linear-gradient(90deg, rgba(6, 3, 18, 1) 0%, rgba(57, 46, 114, 1) 65%, rgba(128, 128, 184, 1) 100%);

  // background: rgb(2, 0, 36);
  // background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(25, 32, 72, 1) 35%, rgba(99, 114, 195, 1) 100%);

  background: rgb(6,3,18);
  background: linear-gradient(90deg, rgba(6,3,18,1) 0%, rgba(57,46,114,1) 100%, rgba(128,128,184,1) 100%);
}

.bg-gradient-indigo-header {
  // background: rgb(6, 3, 18);
  // background: linear-gradient(90deg, rgba(6, 3, 18, 1) 0%, rgba(57, 46, 114, 1) 65%, rgba(128, 128, 184, 1) 100%);

  background: rgb(6,3,18);
  background: linear-gradient(90deg, rgba(6,3,18,1) 0%, rgba(57,46,114,1) 100%, rgba(128,128,184,1) 100%);
  
  // background: rgb(2, 0, 36);
  // background: linear-gradient(30deg, rgba(2, 0, 36, 1) 3%, rgba(35, 45, 100, 1) 50%, rgba(99, 114, 195, 1) 95%);
}

.form-signin {
  width: 100%;
  max-width: 300px;
  padding: 0px;
  // padding-left: 6rem;
  margin: auto;
  margin-top: 0.2em;
  height: auto;
}

.form-signin .checkbox {
  font-weight: 400;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="text"] {
  margin-bottom: 2px;
  border-bottom-right-radius: 2;
  border-bottom-left-radius: 2;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 2;
  border-top-right-radius: 2;
}


.container-footer {
  padding: 200px 15px 0;
}


// new***************************

.socials {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  transition-delay: 0s;
  display: inline-block;
  width: auto;
  overflow: hidden;
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.socials li {
  float: right;
  // width: 1em;
  // padding: 1rem;  
}

.socials a:hover {
  background: #f5f5f5;
}

.socials[class*="vertical"]>li {
  float: none;
}

.socials[class*="-sm"] a {
  padding: .75rem;
}

.socials[class*="bordered"] a,
.socials[class*="boxed"] a {
  border-radius: 0;
}

.text-white .socials a,
.user-caption .socials a {
  color: #fff;
}

.text-white .socials a:hover,
.user-caption .socials a:hover {
  background: rgba(255, 255, 255, 0.2);
}

.logo-sm {
  max-height: 2rem;
}

.navbar-logo {
  width: auto;
  max-height: 100%;
  display: none;
}

.navbar-logo-light {
  display: block !important;
}

@media (max-width: 1199.98px) {
  .navbar-expand-lg>.container,
  .navbar-expand-lg>.container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

//passwor
.form-passwor {
  width: 100%;
  max-width: 28rem;
  padding: 0px;
  margin: auto;
  margin-top: 0.2em;
  height: auto;
}