html{
  // font-family: sans-serif;
  font-family: "Circular", Arial, Helvetica, sans-serif; //font spotify default
  // line-height: 1.15;
  line-height: 1;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  // font-size: 16px !important;
  margin: 0;
  font-family: "Circular", "Hind Vadodara", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;  
  // font-size: 0.9375rem;
  //font-size: 1.2rem;
  font-weight: 400;
  font-weight: 350;
  // line-height: 1.5;
  line-height: 1;
  color: #3e4676;
  text-align: left;
  // background-color: #FFFFFF;
  // background-color: #3e4676;
}


//sidebar
.sidebar-brand {
  // padding: 1rem;
  padding: 0.2rem 2rem 0.2rem 1rem;
  //background: #153D77;
  // border-color: var(--yellow-800);
  // background: #1B4B94;   
  // font-size: 1.175rem;  
  font-weight: 500;
  // color:#fff;
  width: 100%;
  border-radius: 0.1rem;
  // border-bottom-left-radius: 1rem;
  // border-top-right-radius: 1rem;
  // display: block;
  // text-decoration: none; 
}

.sidebar-content {
  //  background: #153D77;  
  transition: 
    margin-left 0.3s ease-in-out, left 0.3s ease-in-out, 
    margin-right 0.3s ease-in-out, right 0.3s ease-in-out;
  height: 100%;
  min-height: 100%;
  // display: flex;
  flex-direction: column;  
  padding: 0rem 1rem 1rem;
}

.sidebar-user {
  padding: 0.5rem 0rem 0rem;
  // background: #fff;
  // color: #153d77;
  color: #fff;
  // background: #153d79;
  //background: #153d79;
  // background: #F48884 ;
  text-align: center; 
}

  .sidebar-user img {
    width: 64px;
    height: 64px; 
  }


  .align-middle {
    vertical-align: middle !important; }

   .filter-view {
      background: #9DD3AF;
   } 

  //  .panel-heading {
  //   background-image: none;
  //   background-color:#9DD3AF;    
  //   // color: white;
  // }


  // HISTORIA CLINNICA TITLE

  .hc-sidebar-user {
    // padding: 1.5rem 1rem 1rem;
    // background: #fff;
    // color: #153d77;
    color: #fff;
    // background: #153d79;
    background: #FFFFFF;
    // background: #F48884 ;
    text-align: center; 
  }
  
    .hc-sidebar-user img {
      width: 124px;
      height: 144px; 
    }


    /* ScrollTopDemo.css */

.custom-scrolltop {
  width: 2rem;
  height: 2rem;
  border-radius: 4px;
  background-color: var(--primary-color) !important;
}
.custom-scrolltop:hover {
  background-color: var(--primary-color) !important;
}

.custom-scrolltop .p-scrolltop-icon {
  font-size: 1rem;
  color: var(--primary-color-text);
}